html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow: hidden;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.outer-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #202124;
  color: #ffffff;
  font-family: Arial, sans-serif;
  transition: background-color 0.3s, color 0.3s;
  border: 3px solid #dca3a3;
}

.container {
  text-align: center;
}

.button {
  background-color: #61dafb;
  color: #fff;
  padding: 0.5rem 1rem;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.button:hover {
  background-color: #0073e6;
}